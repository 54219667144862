/** @jsx jsx */
import { jsx, Heading } from 'theme-ui';
import { PageFooter, HeaderSpacing } from "../components/page"
import { Fragment } from "react"
import { useTranslation, Trans } from 'react-i18next';

const NotFoundPage = () => {
  const { t } = useTranslation()
  return (
    <Fragment>
      <div
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          m: 4,
        }}
      >
        <HeaderSpacing />
        <Heading variant='headings.2'><Trans>There isn’t a page here.</Trans></Heading>
      </div>

      <PageFooter />
    </Fragment>
  )
}

export default NotFoundPage
